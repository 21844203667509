import React from 'react'
import '../investmentInSport/InvestmentInSport.scss'
import InvestmentInSportImg from '../../assets/investment-in-sport.jpg'
import { HiArrowSmRight } from 'react-icons/hi'

import { FadeIn } from 'react-slide-fade-in'

export const InvestmentInSport = ({ investmentInSportRef }) => {
    return (
        <div className='investment-in-sport' id='investmentInSport' ref={investmentInSportRef}>
            <img src={InvestmentInSportImg} alt="" />
            <div className='investment-in-sport-content'>
                <div className="investment-in-sport-content-top-caption">
                    <h1>Fokus 3</h1>
                </div>
                <div className="investment-in-sport-content-title">
                    <FadeIn>
                        <h1>Investment in Sport Enterprises & Development</h1>
                    </FadeIn>
                </div>
                <div className="investment-in-sport-content-info">
                    <FadeIn>
                        <p>This category involves holding companies that invest their funds by creating subsidiaries to conduct investments in sports infrastructures and projects.
                            <br /><br />
                            These holding companies exercise operational control over their subsidiaries and oversee the management and operations of their associated units.</p>
                    </FadeIn>
                </div>
                <div className="investment-in-sport-content-redirect-btn">
                    <button>Contact Us<HiArrowSmRight size={20} /></button>
                </div>
            </div>
        </div>
    )
}
