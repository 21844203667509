import React, { useState } from 'react'
import '../contact/Contact.scss'
import { HiArrowSmRight } from 'react-icons/hi'

import { FadeIn } from 'react-slide-fade-in'

import emailjs from 'emailjs-com';

export const Contact = () => {

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    const serviceId = 'service_3pg3cer';
    const templateId = 'template_bq59bam';
    const userId = 'eAWAWLxPM141aSNr7';

    emailjs.sendForm(serviceId, templateId, e.target, userId)
      .then((result) => {
        console.log('Message sent successfully:', result.text);
        // Reset form fields
        setFirstName('');
        setLastName('');
        setEmail('');
        setPhone('');
        setMessage('');
      })
      .catch((error) => {
        console.error('Error sending message:', error);
      });
  };

  return (
    <div className='contact' id='contact'>

      <div className="contact-top-caption">
        <h1>Contact and Inquiries</h1>
      </div>
      <div className="contact-reminder">
        <h1>We are always ready to speak with you.</h1>
      </div>

      <FadeIn from='bottom' positionOffset={100}>
        <form onSubmit={handleSubmit}>
          <h1>Contact form</h1>
          <div className='contact-form-div-1'>
            <label>
              <input type="text"
                required
                value={firstName}
                placeholder='First Name (required field)'
                name='firstName'
                onChange={(e) => setFirstName(e.target.value)} />
            </label>
            <label>
              <input type="text"
                required value={lastName}
                placeholder='Last Name (required field)'
                name='lastName'
                onChange={(e) => setLastName(e.target.value)} />
            </label>
          </div>
          <div className='contact-form-div-2'>
            <label>
              <input type="email"
                required value={email}
                placeholder='E-mail (required field) '
                name='email'
                onChange={(e) => setEmail(e.target.value)} />
            </label>
            <label>
              <input type="tel"
                value={phone}
                placeholder='Phone'
                name='phone'
                onChange={(e) => setPhone(e.target.value)} />
            </label>
          </div>
          <div>
            <label className='message-input'>
              Message
              <textarea value={message}
                onChange={(e) => setMessage(e.target.value)}
                name='message'
                maxLength={600} />
            </label>
          </div>
          <button type="submit" className='submit-btn'>Submit<HiArrowSmRight size={20} /></button>
        </form>
      </FadeIn>

    </div>
  )
}
