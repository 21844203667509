import React, { useState, useRef, useEffect } from 'react'
import '../topSlider/TopSlider.scss'

import { TopSliderTabs } from '../topSliderTabs/TopSliderTabs'
import { Sidebar } from '../sidebar/Sidebar'

import logo from '../../assets/puba-network-logo.png'
import overlay1 from '../../assets/overlay-1.png'
import overlay2 from '../../assets/overlay-2.png'

import { FaBars } from 'react-icons/fa';

import { FadeIn } from 'react-slide-fade-in'

export const TopSlider = ({ sportResearchRef, sportServicesRef, investmentInSportRef, projectDevelopmentRef }) => {

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  }

  return (
    <div className='top-slider'>
      <img src={overlay1} alt="" className='overlay-1' />
      <img src={overlay2} alt="" className='overlay-2' />
      <div className="top-slider-wrapper">
        <div className="top-slider-header">
          <div className="top-slider-header-logo">
            <img src={logo} alt="Puba logo" />
          </div>
          <div className="top-slider-header-hamburger-menu">
            <button onClick={toggleSidebar}><FaBars size={20} /></button>
          </div>
          {sidebarOpen && <div id='overlay' className="black-overlay"></div>}
          {sidebarOpen && <Sidebar setSidebarOpen={setSidebarOpen} sidebarOpen={sidebarOpen} />}
        </div>
      </div>
      <div className="top-slider-content">
        <div className="top-slider-content-caption">
          <FadeIn>
            <h1>Sport Research & Consultancies</h1>
          </FadeIn>
        </div>
        <div className="top-slider-content-info">
          <FadeIn>
            <h1>Transforming sports <br /> through cutting-edge <br /> research and expert <br /> consultation.</h1>
          </FadeIn>
        </div>
        <div className="top-slider-content-tabs">
          <TopSliderTabs
            sportResearchRef={sportResearchRef}
            sportServicesRef={sportServicesRef}
            investmentInSportRef={investmentInSportRef}
            projectDevelopmentRef={projectDevelopmentRef}
          />
        </div>
      </div>
    </div>
  )
}
