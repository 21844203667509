import React, {useRef, useEffect} from 'react'
import './Sidebar.scss'

import { BsChevronRight } from 'react-icons/bs'
import { SlClose } from 'react-icons/sl'

import logo from '../../assets/puba-network-logo-white.png'

export const Sidebar = ({ setSidebarOpen, sidebarOpen }) => {

  const sidebarRef = useRef(null);

  const closeSidebar = () => {
    document.getElementsByClassName("navbar")[0].style.animation = "slideBackSidebar 0.5s ease";
    document.getElementById("overlay").style.animation = "fadeOutOverlay 0.5s ease";
    setTimeout(function () {
      setSidebarOpen(false);
    }, 450);
  }

  const handleClickOutside = (event) => {
    if(sidebarRef.current && !sidebarRef.current.contains(event.target)){
      closeSidebar();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  }, []);

  return (
    <nav className="navbar" ref={sidebarRef}>
      <div className="navbar-close-btn">
        <button onClick={() => closeSidebar()}><SlClose size={30} /></button>
      </div>
      <div className="navbar-navigation">
        <div style={{ display: "flex", alignItems: "center" }}>
          <BsChevronRight className='link-chevron' />
          <a href='#sportResearch'>Sport Research & Consultancies</a>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <BsChevronRight className='link-chevron' />
          <a href='#sportServices'>Sport Services</a>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <BsChevronRight className='link-chevron' />
          <a href='#investmentInSport'> Investment in Sport Enterprises & Development</a>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <BsChevronRight className='link-chevron' />
          <a href='#projectDevelopment'> Project Development Consultant</a>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <BsChevronRight className='link-chevron' />
          <a href='#investmentInCommercial'>Investment in Commercial Enterprises & Management</a>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <BsChevronRight className='link-chevron' />
          <a href='#informationTechnology'> Information Technology Consultants</a>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <BsChevronRight className='link-chevron' />
          <a href='#contact'> Contact</a>
        </div>
      </div>
      <div className="navbar-logo">
        <img src={logo} alt="" />
      </div>
    </nav>
  )
}
