import React from 'react'
import '../footer/Footer.scss'
import logo from '../../assets/puba-network-logo-white.png'
// import facebook from '../../assets/facebook.png'
// import twitter from '../../assets/twitter.png'
// import instagram from '../../assets/instagram.png'
// import youtube from '../../assets/youtube.png'
import { CiFacebook, CiTwitter, CiInstagram, CiYoutube } from 'react-icons/ci'

export const Footer = () => {
    return (
        <div className='footer'>
            
            <div className="footer-upper">
                <div className="footer-upper-content">
                    <div className="row between margin-row footer-upper-content-newsletter">
                        <img src={logo} alt="Puba Logo" />
                        <h1>Newsletter Subscription</h1>
                    </div>
                    <div className="row margin-row footer-upper-content-subscription">
                        <p>Transforming sports through cutting-edge research <br /> and expert consultation.</p>
                        <div style={{ marginLeft: "auto" }}>
                            <input type="text" placeholder='Enter your email' />
                            <button>Subscription</button>
                        </div>
                    </div>
                    <div className=" margin-row">
                        <div className="navigation">
                            <a href="#sportResearch">Sport Research & <br /> Consultancies</a>
                            <a href="#sportServices">Sports <br /> Services</a>
                            <a href="#investmentInSport">Investment in Sport <br /> Enterprises & Development</a>
                            <a href="#projectDevelopment">Project Development <br /> Consultant</a>
                            <a href="#investmentInCommercial">Investment in Commercial <br /> Enterprises & Management</a>
                            <a href="#informationTechnology">Information Technology <br /> Consultants</a>
                            <a href="#contact">Contact</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer-bottom">
                <div className="footer-bottom-content">
                    <div className="footer-bottom-content-copyright">
                        <p>2023 &copy; Puba-Network.com</p>
                    </div>
                    <div className="footer-bottom-content-social-media">
                        <a href="!#"><CiFacebook size={27} /></a>
                        <a href="!#"><CiTwitter size={27} /></a>
                        <a href="!#"><CiInstagram size={27} /></a>
                        <a href="!#"><CiYoutube size={27} /></a>
                    </div>
                </div>
            </div>

        </div>
    )
}
