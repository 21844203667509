import React from 'react'
import '../sportResearch/SportResearch.scss'
import SportResearchImg from '../../assets/sport-research.jpg'
import { HiArrowSmRight } from 'react-icons/hi'

import { FadeIn } from 'react-slide-fade-in'

export const SportResearch = ({ sportResearchRef }) => {
  return (
    <div className='sport-research' id='sportResearch' ref={sportResearchRef}>
      <img src={SportResearchImg} alt="" />
      <div className='sport-research-'></div>
      <div className='sport-research-content'>
        <div className="sport-research-content-top-caption">
          <h1>Fokus 1</h1>
        </div>
        <div className="sport-research-content-title">
          <FadeIn>
            <h1>Sport Research & Consultancies</h1>
          </FadeIn>
        </div>
        <div className="sport-research-content-info">
          <FadeIn>
            <p>This category involves providing support and guidance to sports clubs and organizations on organizational and developmental issues.
              <br /><br />
              Such issues include strategic planning, financial budgeting and planning, creating objectives <br /> and policies for marketing and advertising, <br /> sport psychology, and human resources <br /> policies.</p>
          </FadeIn>
        </div>
        <div className="sport-research-content-redirect-btn">
          <button>Contact Us<HiArrowSmRight size={20} /></button>
        </div>
      </div>
    </div>
  )
}
