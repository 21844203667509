import React from 'react'
import '../projectDevelopment/ProjectDevelopment.scss'
import { HiArrowSmRight } from 'react-icons/hi'

import { FadeIn } from 'react-slide-fade-in'

export const ProjectDevelopment = ({ projectDevelopmentRef }) => {
    return (
        <div className='project-development' id='projectDevelopment' ref={projectDevelopmentRef}>
            <div className='project-development-content'>
                <div className="project-development-content-top-caption">
                    <h1>Fokus 4</h1>
                </div>
                <div className="project-development-content-title">
                    <FadeIn>
                        <h1>Project Development Consultant</h1>
                    </FadeIn>
                </div>
                <div className="project-development-content-info">
                    <FadeIn>
                        <p>This category encompasses companies that specialize in providing analysis and <br /> consultation services on the development of real estate projects conducted by real estate investment firms and other related companies.
                            <br /><br />
                            These services cover various aspects, such as identifying project elements, devising development strategies, assessing economic feasibility and marketing opportunities, <br /> revising development requirements and obstacles, and supervising the execution process.</p>
                    </FadeIn>
                </div>
                <div className="project-development-content-redirect-btn">
                    <button>Contact Us<HiArrowSmRight size={20} /></button>
                </div>
            </div>
        </div>
    )
}
