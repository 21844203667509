import React from 'react'
import '../topSliderTabs/SliderTab.scss'

export const SliderTab = ({ icon, caption, title, handleScroll, currentRef }) => {
    return (
        <div className="slider-tab" onClick={() => handleScroll(currentRef)}>
            <div className="slider-tab-top-content">
                <img src={icon} alt="" />
                <h1>{caption}</h1>
            </div>
            <div className="slider-tab-bottom-content">
                <h1>{title}</h1>
            </div>
        </div>
    )
}
