import React from 'react'
import '../informationTechnology/InformationTechnology.scss'
import { HiArrowSmRight } from 'react-icons/hi'
import DiscussionIcon from '../../assets/discussion-icon.png'

import { FadeIn } from 'react-slide-fade-in'

export const InformationTechnology = () => {
    return (
        <div className='information-technology' id='informationTechnology'>
            <div className='information-technology-content'>
                <div className="information-technology-content-top-caption">
                    <h1>Fokus 6</h1>
                </div>
                <div className="information-technology-content-title">
                    <FadeIn>
                        <h1>Information Technology Consultants</h1>
                    </FadeIn>
                </div>
                <div className="information-technology-content-info">
                    <FadeIn>
                        <p>The services offered involve providing technical support and consulting in the field of information technology and its applications such as internet and data warehousing to improve the administrative and technical performance of various companies.
                            <br /><br />
                            Along with consultancy services, the company may also provide software proposals and conduct surveys and studies to determine the appropriate technology that aligns with the company's nature. It includes offering suggestions on the most suitable technical applications for transitioning to blockchain technology, emphasizing on the economic benefits expected from applying it in different domains. However, it should be noted that these firms are not authorized to market or manufacture any software or products related to this technology.</p>
                    </FadeIn>
                </div>
                <div className="information-technology-content-redirect-btn">
                    <button>Contact Us<HiArrowSmRight size={20} /></button>
                </div>
            </div>
            <div className="information-technology-box">
                <img src={DiscussionIcon} alt="" />
                <h1>Driving the <br /> growth of <br /> sports through strategic investments <br /> and sustainable development.</h1>
                <p>Puba network</p>
            </div>
        </div>
    )
}
