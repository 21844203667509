import React from 'react'
import '../sportServices/SportServices.scss'
import SportServicesImg from '../../assets/sport-services.jpg'
import { HiArrowSmRight } from 'react-icons/hi'

import { FadeIn } from 'react-slide-fade-in'

export const SportServices = ({ sportServicesRef }) => {
    return (
        <div className='sport-services' id='sportServices' ref={sportServicesRef}>
            <div className='sport-services-content'>
                <div className="sport-services-content-top-caption">
                    <h1>Fokus 2</h1>
                </div>
                <div className="sport-services-content-title">
                    <FadeIn>
                        <h1>Sport Services</h1>
                    </FadeIn>
                </div>
                <div className="sport-services-content-info">
                    <FadeIn>
                        <p> This category comprises the offering of a variety of specialized services to sports teams or clubs.
                            <br /><br />
                            Such as facilitating contract negotiations with <br /> coaches, arranging team camps for pre-season <br /> training both indoors and outdoors, contacting international sports clubs to participate in local <br /> sporting events, and managing indoor or <br /> outdoor sports arenas.
                        </p>
                    </FadeIn>
                </div>
                <div className="sport-services-content-redirect-btn">
                    <button>Contact Us<HiArrowSmRight size={20} /></button>
                </div>
            </div>
            <img src={SportServicesImg} alt="" />
        </div>
    )
}
