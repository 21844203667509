import './App.css';
import React, { useRef, useState, useEffect } from 'react'

import { TopSlider } from './components/topSlider/TopSlider';
import { SportResearch } from './components/sportResearch/SportResearch';
import { SportServices } from './components/sportServices/SportServices';
import { InvestmentInSport } from './components/investmentInSport/InvestmentInSport';
import { ProjectDevelopment } from './components/projectDevelopment/ProjectDevelopment';
import { InvestmentInCommercial } from './components/investmentInCommercial/InvestmentInCommercial';
import { InformationTechnology } from './components/informationTechnology/InformationTechnology';
import { Contact } from './components/contact/Contact';
import { Footer } from './components/footer/Footer';

import { FaArrowUp } from 'react-icons/fa'

function App() {

  const sportResearchRef = useRef(null);
  const sportServicesRef = useRef(null);
  const investmentInSportRef = useRef(null);
  const projectDevelopmentRef = useRef(null);

  // Function for scrolling to top of the page
  function ScrollToTop() {
    const [isVisible, setIsVisible] = useState(false);

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    };

    useEffect(() => {
      const toggleVisibility = () => {
        if (window.pageYOffset > 800) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      };

      window.addEventListener('scroll', toggleVisibility);

      return () => window.removeEventListener('scroll', toggleVisibility);
    }, []);

    return (
      <div className="scroll-to-top">
        {isVisible && (
          <div onClick={scrollToTop}>
            <FaArrowUp />
          </div>
        )}
      </div>
    );
  }

  return (
    <div className='wrapper'>
      {ScrollToTop()}
      <TopSlider
        sportResearchRef={sportResearchRef}
        sportServicesRef={sportServicesRef}
        investmentInSportRef={investmentInSportRef}
        projectDevelopmentRef={projectDevelopmentRef} />
      <SportResearch sportResearchRef={sportResearchRef} />
      <SportServices sportServicesRef={sportServicesRef} />
      <InvestmentInSport investmentInSportRef={investmentInSportRef} />
      <div className="sections-4-5">
        <ProjectDevelopment projectDevelopmentRef={projectDevelopmentRef} />
        <InvestmentInCommercial />
      </div>
      <InformationTechnology />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
