import React from 'react'
import '../investmentInCommercial/InvestmentInCommercial.scss'
import { HiArrowSmRight } from 'react-icons/hi'

import { FadeIn } from 'react-slide-fade-in'

export const InvestmentInCommercial = () => {
    return (
        <div className='investment-in-commercial' id='investmentInCommercial'>
            <div className='investment-in-commercial-content'>
                <div className="investment-in-commercial-content-top-caption">
                    <h1>Fokus 5</h1>
                </div>
                <div className="investment-in-commercial-content-title">
                    <FadeIn delayInMilliseconds={200}>
                        <h1>Investment in Commercial Enterprises & Management</h1>
                    </FadeIn>
                </div>
                <div className="investment-in-commercial-content-info">
                    <FadeIn delayInMilliseconds={200}>
                        <p>This category includes companies and holding companies that are created to invest their <br /> funds by establishing sub-companies that <br /> engage in various trading operations, in addition to transport, contracting, and financing activities.
                            <br /><br />
                            These companies may also invest in other commercial corporations by acquiring shares. Furthermore, this category includes the management of these subsidiary companies and organizing their operations.</p>
                    </FadeIn>
                </div>
                <div className="investment-in-commercial-content-redirect-btn">
                    <button>Contact Us<HiArrowSmRight size={20} /></button>
                </div>
            </div>
        </div>
    )
}
