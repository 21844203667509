import React from 'react'
import '../topSliderTabs/TopSliderTabs.scss'

import trophyIcon from '../../assets/trophy-icon.png'
import badgeIcon from '../../assets/badge-icon.png'
import investmentIcon from '../../assets/investment-icon.png'
import supportIcon from '../../assets/support-icon.png'

import { SliderTab } from './SliderTab'

export const TopSliderTabs = ({ sportResearchRef, sportServicesRef, investmentInSportRef, projectDevelopmentRef }) => {

    const handleScroll = (currentRef) => {
        currentRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    return (
        <div className='top-slider-tabs'>
            <SliderTab
                icon={trophyIcon}
                caption="Fokus 1"
                title="Sport Research & Consultancies"
                handleScroll={handleScroll}
                currentRef={sportResearchRef}
            />
            <SliderTab
                icon={badgeIcon}
                caption="Fokus 2"
                title="Sport Services"
                handleScroll={handleScroll}
                currentRef={sportServicesRef}
            />
            <SliderTab
                icon={investmentIcon}
                caption="Fokus 3"
                title="Investment in Sport Enterprises & Development"
                handleScroll={handleScroll}
                currentRef={investmentInSportRef}
            />
            <SliderTab
                icon={supportIcon}
                caption="Fokus 4"
                title="Project Development Consultant"
                handleScroll={handleScroll}
                currentRef={projectDevelopmentRef}
            />
        </div>
    )
}
